import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PATHNAME_ONBOARDING__APP_DOWNLOAD } from '@app/src/constants/onboardingConstants';
import { useIsChase } from '@app/src/hooks/useIsChase';
import { contentSelector, initialLoadSelector, loadingSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';

const QuestionHeader = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const isChase = useIsChase();

  const content = useSelector(contentSelector);
  const initialLoad = useSelector(initialLoadSelector);
  const loading = useSelector(loadingSelector);

  const [formattedTitle, setFormattedTitle] = useState('');
  const [formattedSubtitle, setFormattedSubtitle] = useState('');

  const title = _.get(content, `${pathname}.title`, '');
  const subtitle = _.get(content, `${pathname}.subtitle`, '');

  const appDownloadOverride = pathname === PATHNAME_ONBOARDING__APP_DOWNLOAD;

  useEffect(() => {
    if (!loading) {
      const formattedTitle = isChase ? 'Finish linking Chase in the app' : dispatch(getStringSubstitutions(title));
      const formattedSubtitle = dispatch(getStringSubstitutions(subtitle));

      setFormattedTitle(formattedTitle);
      setFormattedSubtitle(formattedSubtitle);
    }
  }, [title, subtitle, loading, dispatch, isChase]);

  return (
    <div className='h-onboarding-content-sticky'>
      <div
        className='h-onboarding-content-question-title'
        style={{ textAlign: appDownloadOverride ? 'center' : 'inherit' }}
      >
        {initialLoad ? (
          <Skeleton variant='text' width={300} height={40} animation='pulse' />
        ) : (
          <div>{formattedTitle}</div>
        )}
      </div>
      {appDownloadOverride ? null : (
        <div className='h-onboarding-content-question-subtitle'>
          {initialLoad ? (
            <Skeleton variant='text' width={150} height={20} animation='pulse' />
          ) : (
            <div>{subtitle && <ReactMarkdown>{formattedSubtitle}</ReactMarkdown>}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionHeader;
