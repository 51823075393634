import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import { baseApi } from '@app/src/api/baseApi';
import { useUIState } from '@app/src/hooks/useUIState';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

// 5 minutes
const REPORTING_FREQ_MS = 5 * 60_000;

/**
 * Log api (rtk-query) state to sentry (in-questionnaire / in-review native users only)
 * Using temporarily to debug https://linear.app/keepertax/issue/KEE-1630/navigation-file-taxes-property-including-warnings-for-submit-pin
 */
export const useLogApiState = () => {
  const apiState = useSelector((state) => _.get(state, baseApi.reducerPath));
  const isWebviewFocused = useSelector(isWebviewFocusedSelector);
  const { uiState } = useUIState();
  useInterval(() => {
    if (!isWebviewFocused || (uiState?.slug !== 'questionnaire' && uiState?.slug !== 'review')) {
      return;
    }
    const cleanedApiState = {
      ...apiState,
      // Omit data - data can be large and may contain sensitive info
      queries: _.mapValues(apiState?.queries, (queryData) => _.omit(queryData, 'data')),
      mutations: _.mapValues(apiState?.mutations, (mutationData) => _.omit(mutationData, 'data')),
      subscriptions: _.mapValues(apiState?.subscriptions, (subscriptions) => _.size(subscriptions))
    };
    defaultCaptureMessage('api state', { state: JSON.stringify(cleanedApiState) }, 'log');
  }, REPORTING_FREQ_MS);
};
