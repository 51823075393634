import React from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { LoadingContext } from '@app/src/Components/TaxProfile/LoadingContext';
import TaxEstimateDetailsRow from '@app/src/Components/TaxProfile/TaxEstimateDetailsRow';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { colorSecondary } from '@app/src/theme';

const TaxEstimateDetails = ({ navigateToForm, taxEstimate, isLoading }) => {
  const year = useSelector(expensesYearSelector);

  const unknownAdjustments =
    taxEstimate?.federal?.totalIncome -
    taxEstimate?.federal?.deductions?.scheduleC -
    taxEstimate?.federal?.selfEmploymentTax / 2 -
    taxEstimate?.federal?.adjustedGrossIncome;

  return (
    <LoadingContext.Provider value={isLoading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          lineHeight: '1.5',
          width: 450,
          paddingBottom: 58
        }}
      >
        <header
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 500
            }}
          >
            Tax estimate ({year})
          </h2>
        </header>
        <hr style={{ width: '100%', marginBottom: 16, marginTop: 0 }} />
        <TaxEstimateDetailsRow label='Total income' value={taxEstimate?.federal?.totalIncome} />
        <TaxEstimateDetailsRow label='Business deductions' value={-taxEstimate?.federal?.deductions?.scheduleC} />
        {taxEstimate?.federal?.selfEmploymentTax > 0 && (
          <TaxEstimateDetailsRow
            label='Self employment tax deduction'
            value={-taxEstimate?.federal?.selfEmploymentTax / 2}
          />
        )}
        {unknownAdjustments > 0 && (
          <TaxEstimateDetailsRow
            label='Other deductions'
            value={
              -(
                taxEstimate?.federal?.totalIncome -
                taxEstimate?.federal?.deductions?.scheduleC -
                taxEstimate?.federal?.selfEmploymentTax / 2 -
                taxEstimate?.federal?.adjustedGrossIncome
              )
            }
          />
        )}
        <hr style={{ width: '100%' }} />
        <TaxEstimateDetailsRow label='Adjusted gross income' value={taxEstimate?.federal?.adjustedGrossIncome} />
        <TaxEstimateDetailsRow label='Standard deduction' value={-taxEstimate?.federal?.zeroBracketAmount} />
        <TaxEstimateDetailsRow label='QBI deductions' value={-taxEstimate?.federal?.otherDeductions} />
        <hr style={{ width: '100%' }} />
        <TaxEstimateDetailsRow label='Taxable income' value={taxEstimate?.federal?.taxableIncome} />
        <hr style={{ width: '100%' }} />
        <TaxEstimateDetailsRow label='Federal tax liability' value={taxEstimate?.federal?.totalTaxLiability} />
        {!!taxEstimate?.state && (
          <TaxEstimateDetailsRow
            label={(() => {
              if (taxEstimate?.state?.stateAbbreviation) {
                return `${taxEstimate?.state?.stateAbbreviation} tax liability`;
              }

              return 'State tax liability';
            })()}
            value={taxEstimate?.state?.totalTaxLiability}
          />
        )}
        <TaxEstimateDetailsRow label='Credits' value={-taxEstimate?.federal?.creditsApplied} />
        <TaxEstimateDetailsRow label='Taxes withheld' value={-taxEstimate?.federal?.totalTaxesWithheld} />
        <TaxEstimateDetailsRow label='Quarterly tax payments' value={-taxEstimate?.federal?.quarterlyTaxPayments} />
        <hr style={{ width: '100%' }} />
        <TaxEstimateDetailsRow
          label={taxEstimate?.federal?.taxesOwed > 0 ? 'Estimated federal tax bill' : 'Estimated federal refund'}
          value={taxEstimate?.federal?.taxesOwed}
          fontWeight={500}
          signDisplay='never'
        />
        {!!taxEstimate?.state && (
          <TaxEstimateDetailsRow
            label={(() => {
              if (!taxEstimate?.state?.stateAbbreviation) {
                return 'Estimated state refund';
              }

              if (taxEstimate?.state?.taxesOwed > 0) {
                return `Estimated ${taxEstimate?.state?.stateAbbreviation} tax bill`;
              }

              return `Estimated ${taxEstimate?.state?.stateAbbreviation} refund`;
            })()}
            value={taxEstimate?.state?.taxesOwed ?? 0}
            fontWeight={500}
            signDisplay='never'
          />
        )}
        <hr style={{ width: '100%' }} />
        <TaxEstimateDetailsRow
          fontSize={20}
          fontWeight={500}
          label={(() => {
            const federalTaxesOwed = taxEstimate?.federal?.taxesOwed;
            const stateTaxesOwed = taxEstimate?.state?.taxesOwed ?? 0;
            const overallTaxesOwed = federalTaxesOwed + stateTaxesOwed;

            if (federalTaxesOwed > 0 && stateTaxesOwed > 0) {
              return 'Estimated total tax bill';
            }

            if (federalTaxesOwed <= 0 && stateTaxesOwed <= 0) {
              return 'Estimated total tax refund';
            }

            if (overallTaxesOwed > 0) {
              return 'Estimated net tax bill';
            }

            return 'Estimated net tax refund';
          })()}
          value={taxEstimate?.federal?.taxesOwed + (taxEstimate?.state?.taxesOwed ?? 0)}
          signDisplay='never'
        />
        <Button
          sx={{
            borderWidth: '1.5px',
            borderStyle: 'solid',
            borderColor: colorSecondary,
            marginTop: 2,
            borderRadius: 1,
            flex: 1
          }}
          disabled={isLoading}
          onClick={() => {
            navigateToForm();
          }}
        >
          Improve this estimate
        </Button>
      </div>
    </LoadingContext.Provider>
  );
};

export default TaxEstimateDetails;
