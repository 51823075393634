import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ProgressBarWithTitle from '@app/src/Components/Common/ProgressBarWithTitle/ProgressBarWithTitle';
import {
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHS_WITH_RETRO_LOADING_PROGRESS_BAR
} from '@app/src/constants/onboardingConstants';
import { useIsChase } from '@app/src/hooks/useIsChase';
import { bankListSelector } from '@app/src/selectors/bankSelectors';

const RetroLoadingProgressBar = () => {
  const { pathname } = useLocation();
  const isChase = useIsChase();

  const [progress, setProgress] = useState(43);
  const skippedBankLink = useSelector(bankListSelector).length === 0;

  const isLoadingComplete = pathname === PATHNAME_ONBOARDING__APP_DOWNLOAD || progress === 100;
  const isVisible = PATHS_WITH_RETRO_LOADING_PROGRESS_BAR.includes(pathname) && !isChase;

  const title = skippedBankLink
    ? isLoadingComplete
      ? 'Account setup complete!'
      : 'Setting up your account'
    : isLoadingComplete
      ? 'Scan complete!'
      : 'Scanning for deductions';

  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible) {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }

          return prevProgress + 1;
        });
      }
    }, 1000);

    if (pathname === PATHNAME_ONBOARDING__APP_DOWNLOAD) {
      clearInterval(interval);
      setProgress(100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pathname, isVisible]);

  return isVisible ? <ProgressBarWithTitle title={title} progress={progress} /> : null;
};

export default RetroLoadingProgressBar;
