import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import LinkedAccountLoader from '@app/src/Components/Bank/LinkedAccountLoader/LinkedAccountLoader';
import PlaidSecurityNotice from '@app/src/Components/HolisticOnboarding/components/PlaidSecurityNotice/PlaidSecurityNotice';
import { setHolisticOnboardingLoading } from '@app/src/actions/holisticOnboardingActions';
import { bankLinkPendingSelector, bankListSelector } from '@app/src/selectors/bankSelectors';
import { loadingSelector, webviewBankLinkedSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getBankSummaryV2 } from '@app/src/services/bankService';
import { getWorkInfo } from '@app/src/services/workService';

const OnboardingBankLink = () => {
  const dispatch = useDispatch();

  const banklist = useSelector(bankListSelector);
  const webviewBankLinked = useSelector(webviewBankLinkedSelector);
  const bankLinking = useSelector(bankLinkPendingSelector);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if (webviewBankLinked) {
      dispatch(setHolisticOnboardingLoading(true));

      setTimeout(() => {
        dispatch(getBankSummaryV2(() => dispatch(setHolisticOnboardingLoading(false))));
      }, 3000);
    }
  }, [webviewBankLinked, dispatch]);

  useEffect(() => {
    dispatch(getWorkInfo());
  }, [dispatch]);

  return (
    <>
      <BankList origin='onboarding' type='onboarding' />
      {bankLinking && !loading && <LinkedAccountLoader />}
      {banklist.length === 0 && <PlaidSecurityNotice />}
    </>
  );
};

export default OnboardingBankLink;
