import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import { baseApi } from '@app/src/api/baseApi';
import { isReactNative } from '@app/src/global/Helpers';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

/** How often to check for stalled queries */
const CHECK_INTERVAL_MS = 10_000;

/** How long for a query to be pending prior to being considered stalled */
const STALL_THRESHOLD_MS = 30_000;

/**
 * Reload page upon stalled queries and report to sentry. We're seeing users end up with stale api data, which might be caused by perpetually pending queries
 * This will help
 *  - profile the issue
 *  - unblock users with stalled requests (hard refresh will force api calls to be made from scratch)
 */
export const useReloadOnStuckQuery = () => {
  const apiState = useSelector((state) => _.get(state, baseApi.reducerPath));
  const isWebviewFocused = useSelector(isWebviewFocusedSelector);

  useInterval(async () => {
    // Don't reload when taxes tab in background
    if (!isWebviewFocused && isReactNative()) {
      return;
    }
    const stalledQuery = _.find(
      apiState?.queries,
      (query) => query.status === 'pending' && query.startedTimeStamp < Date.now() - STALL_THRESHOLD_MS
    );
    if (!_.isNil(stalledQuery)) {
      defaultCaptureMessage('query stalled', _.omit(stalledQuery, 'data'), 'error');
      // Ensure warning is sent to sentry prior to reloading
      await Sentry.flush(5000);
      window.location.reload();
    }
  }, CHECK_INTERVAL_MS);
};
