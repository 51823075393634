import _ from 'lodash';
import { createSelector } from 'reselect';

const bankLinksSelector = (state) => _.get(state, ['pricing2', 'bankLinks']);
const isCardValidSelector = (state) => _.get(state, ['pricing2', 'isCardValid']);
export const subscriptionInfoSelector = (state) => _.get(state, ['pricing2', 'subscriptionInfo']);

export const loadingSelector = (state) => _.get(state, ['pricing2', 'loading']);
export const updatingSelector = (state) => _.get(state, ['pricing2', 'updating']);
export const walletTypeSelector = (state) => _.get(state, ['pricing2', 'walletType']);
export const bankSelector = (state) => _.get(state, ['pricing2', 'bank']);
export const originSelector = (state) => _.get(state, ['pricing2', 'origin']);

export const subscriptionTypeSelector = (state) => _.get(state, ['pricing2', 'subscriptionType']);
export const subscriptionTypesSelector = (state) => _.get(state, ['pricing2', 'subscriptionTypes']);
export const plansShownSelector = (state) => _.get(state, ['pricing2', 'plansShown']);
export const membershipTextSelector = (state) =>
  _.get(state, ['pricing2', 'subscriptionInfo', 'subscription', 'membershipText'], []);
export const subscriptionStatusSelector = (state) =>
  _.get(state, ['pricing2', 'subscriptionInfo', 'subscription', 'status']);
export const currentSubscriptionTypeSelector = (state) =>
  _.get(state, ['pricing2', 'subscriptionInfo', 'subscription', 'type']);
export const willCancelSelector = (state) =>
  _.get(state, ['pricing2', 'subscriptionInfo', 'subscription', 'willCancel']);
export const availablePlansSelector = (state) => _.get(state, ['pricing2', 'subscriptionInfo', 'plans'], []);

const overridesSelector = (state) => _.get(state, ['pricing2', 'overrides']);

// eslint-disable-next-line import/no-unused-modules
export const freeTrialSelector = (state) => _.get(state, ['pricing2', 'subscriptionInfo', 'subscription', 'freeTrial']);

export const subscriptionPriceSelector = createSelector(
  [subscriptionTypesSelector, (_, type) => type],
  (subscriptionTypes, type) => _.get(subscriptionTypes, [type, 'price'])
);

export const subscriptionDefaultsSelector = createSelector(
  [subscriptionInfoSelector, overridesSelector, (_, type) => type],
  (subscriptionInfo, overrides, type) => {
    const defaultValues = _.get(subscriptionInfo, ['subscription', 'screens', type], {});
    const screenOverrides = _.get(overrides, type, {});

    return _.mergeWith({}, defaultValues, screenOverrides, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return srcValue;
      }
    });
  }
);

export const chargeableAccountsSelector = createSelector([bankLinksSelector], (bankLinks) => {
  return bankLinks
    .filter((bankLink) => bankLink.linked)
    .flatMap((bankLink) => {
      return bankLink.subAccounts
        .filter((subAccount) => subAccount.chargeable)
        .map((subAccount) => {
          const mask = _.get(subAccount, 'mask') ? _.get(subAccount, 'mask').padStart(4, 0) : null;

          return {
            institution_name: bankLink.institution_name,
            institution_id: bankLink.institution_id,
            logo: bankLink.logo,
            account_id: subAccount.account_id,
            name: subAccount.name,
            mask
          };
        });
    });
});

export const isUpdateEnabledSelector = createSelector([bankSelector, isCardValidSelector], (bank, isCardValid) => {
  return !!bank || isCardValid;
});

export const hasDividerSelector = createSelector(
  [walletTypeSelector, chargeableAccountsSelector],
  (walletType, chargeableAccounts) => {
    return !!walletType || !!chargeableAccounts.length;
  }
);

const pricingObjSelector = (state) => _.get(state, ['pricing2', 'accountDetails']);

export const trialLengthSelector = createSelector([pricingObjSelector], (pricingObj) => {
  return _.get(pricingObj, 'trialLength');
});

export const isFilingOnlySelector = createSelector(
  [currentSubscriptionTypeSelector],
  (currentSubscriptionType) => currentSubscriptionType === 'filing only'
);
