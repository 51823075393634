import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { contentSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import getImageUrl from '@app/src/utils/getImageUrl';

const OnboardingAppDownload = () => {
  const { pathname } = useLocation();

  const content = useSelector(contentSelector);
  const bankLinked = useSelector(bankListSelector).length === 0;

  const bankLinkedCopy = bankLinked ? '' : _.get(content, `${pathname}.options.bankLinked`, '') + ' ';

  const subtitle = bankLinkedCopy + _.get(content, `${pathname}.subtitle`, '');

  const isMobile = useIsMobile();

  const onDownloadClick = () => {
    trackActivity('app store clicked', { store: 'download' });
  };

  const onAppStoreClick = (store) => {
    trackActivity('app store clicked', { store });
    if (store === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.keepertax&hl=en&gl=US', '_blank');
    } else {
      window.open('https://itunes.apple.com/app/id1471090941', '_blank');
    }
  };

  return (
    <div>
      <div className='h-onboarding-content-question-subtitle' style={{ textAlign: 'center' }}>
        {subtitle}
      </div>
      <div className='h-onboarding-content-question-download'>
        {!isMobile && (
          <div className='h-onboarding-content-question-download-reviews'>
            <div style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
              <img src={getImageUrl('download-featured.svg')} alt='Featured' />
            </div>
            <img src={getImageUrl('download-rating.svg')} alt='Rating' />
          </div>
        )}
        <div className='h-onboarding-content-question-download-header'>
          <img
            src={getImageUrl('iphone.svg')}
            alt='iphone'
            className='h-onboarding-content-question-download-header-phone'
          />
        </div>
        <div>
          <div style={{ margin: '16px 0' }}>
            {isMobile ? (
              <div>
                <Button
                  variant='contained'
                  color='secondary'
                  fullWidth
                  type='button'
                  href='https://keeper.tax/get-the-app'
                  onClick={onDownloadClick}
                >
                  Continue
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={getImageUrl('apple-store-logo.svg')}
                      alt='Apple App Store'
                      onClick={() => onAppStoreClick('apple')}
                      role='button'
                    />
                    <img
                      src={getImageUrl('google-store-logo.svg')}
                      alt='Google Play Store'
                      onClick={() => onAppStoreClick('android')}
                      role='button'
                    />
                  </div>
                  <div className='flex column' style={{ alignItems: 'center', width: '50%' }}>
                    <img src={getImageUrl('get-the-app-qr.svg')} alt='QR code' height={130} width={130} />
                    <div className='text-base' style={{ textAlign: 'center' }}>
                      To download, scan QR code with your phone's camera
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingAppDownload;
