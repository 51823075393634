import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import {
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useGetUIStageQuery,
  useUpdateSubmitIssuesMutation
} from '@app/src/api/taxDataApi';
import WarningIcon from '@app/src/assets/warning.svg?react';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { UI_STAGE__CONFIRM_AMOUNTS } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitWarnings.scss';

const TaxFlowSubmitWarnings = ({ setLoading = _.noop }) => {
  const year = useSelector(yearSelector);

  const { data: submitWarnings, isLoading: submitWarningsLoading } = useGetSubmitWarningsQuery({ year });
  const { data: submitIssues, isLoading: submitIssuesLoading } = useGetSubmitIssuesQuery({ year });
  const { data: uiStage, isLoading: isUIStageLoading } = useGetUIStageQuery({ year });
  const [dismissSubmitWarning] = useDismissSubmitWarningMutation();
  const [updateSubmitIssues] = useUpdateSubmitIssuesMutation();

  const isLoading = submitWarningsLoading || submitIssuesLoading || isUIStageLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  const hideButtons = uiStage === UI_STAGE__CONFIRM_AMOUNTS;
  const navigateMakeChanges = ({ link }) => {
    history.push(`${link}`);
  };

  const resolveSubmitWarning = ({ slug, header }) => {
    trackActivity('dismiss submit warning', { slug, header });
    dismissSubmitWarning({ slug, year });
  };

  const resolveSubmitIssue = ({ slug, header, body }) => {
    trackActivity('dismiss submit warning', { slug, header });
    const updatedIssues = _.map(submitIssues, (submitIssue) =>
      _.isMatch(submitIssue, { header: header, body }) ? { ...submitIssue, isChecked: true } : submitIssue
    );
    updateSubmitIssues({ updatedIssues, year });
  };

  if (_.isEmpty(submitWarnings) && _.isEmpty(submitIssues)) {
    return null;
  }

  return (
    <div className='taxflow-submit-warnings'>
      {_.chain(submitIssues)
        .filter({ isChecked: false })
        .map((submitIssue) => (
          <TaxFlowSubmitWarning
            key={submitIssue.header}
            header={submitIssue.header}
            body={submitIssue.body}
            isBlocking={hideButtons}
            link={!hideButtons && submitIssue.link}
            dismissText={submitIssue.isBlocking ? 'I FIXED IT' : 'SKIP'}
            onLinkClick={() => navigateMakeChanges({ link: submitIssue.link })}
            onDismiss={() =>
              resolveSubmitIssue({ slug: submitIssue.slug, header: submitIssue.header, body: submitIssue.body })
            }
          />
        ))
        .value()}
      {_.map(submitWarnings, (submitWarning) => (
        <TaxFlowSubmitWarning
          key={submitWarning.slug}
          header={submitWarning.header}
          body={submitWarning.body}
          isBlocking={hideButtons || submitWarning.isBlocking}
          link={!hideButtons && submitWarning.link}
          dismissText={'SKIP'}
          onLinkClick={() => navigateMakeChanges({ link: submitWarning.link })}
          onDismiss={() => resolveSubmitWarning({ slug: submitWarning.slug, header: submitWarning.header })}
        />
      ))}
    </div>
  );
};

export const TaxFlowSubmitWarning = ({ header, body, isBlocking, link, dismissText, onLinkClick, onDismiss }) => {
  return (
    <div className='taxflow-submit-warning'>
      <div className='taxflow-submit-warning-header'>
        <div className='taxflow-submit-warning-header-lhs'>
          <WarningIcon className='taxflow-submit-warning-header-lhs-icon' width={20} height={20} />
          <div>{header}</div>
        </div>
        <div className='taxflow-submit-warning-header-rhs'>
          {link && (
            <Button fullWidth variant='pill' onClick={onLinkClick}>
              EDIT
            </Button>
          )}
          {isBlocking ? (
            <div className='taxflow-submit-warning-header-rhs-required'>Required</div>
          ) : (
            <Button fullWidth variant='pill' onClick={onDismiss}>
              {dismissText}
            </Button>
          )}
        </div>
      </div>
      {!_.isNil(body) && <div className='taxflow-submit-warning-body'>{body}</div>}
    </div>
  );
};

export default TaxFlowSubmitWarnings;
