import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import SubscriptionFeature from '@app/src/Components/Subscription/Common/SubscriptionFeature';
import { subscriptionTypesSelector } from '@app/src/selectors/pricingSelectors';
import { colorFontDark, colorWarning } from '@app/src/theme';
import '@app/src/Components/Subscription/Common/SubscriptionTypeElement.scss';

export const SubscriptionTypeElement = ({ type, onClick, focused, selected, style, hideBubble, ...props }) => {
  const subscriptions = useSelector(subscriptionTypesSelector);

  const name = _.get(subscriptions, [type, 'name']);
  const price = _.get(subscriptions, [type, 'price']);
  const period = _.get(subscriptions, [type, 'period']);
  const bubbleText = _.get(subscriptions, [type, 'bubbleText']);
  const features = _.get(subscriptions, [type, 'features']);
  const subtitle = _.get(subscriptions, [type, 'subtitle']);
  const lowerRightText = _.get(subscriptions, [type, 'lowerRightText']);
  const warningTitle = _.get(subscriptions, [type, 'warning', 'title']);
  const warningDescription = _.get(subscriptions, [type, 'warning', 'description']);

  const hasWarning = warningTitle && warningDescription && selected;

  return (
    <Button
      className='subscription-type-element'
      onClick={() => onClick(type)}
      style={style}
      sx={{
        height: 'fit-content',
        position: 'relative',
        ...(hasWarning ? { borderColor: colorWarning } : {})
      }}
      variant={selected ? 'subscription-type-selected' : 'subscription-type'}
      {...props}
    >
      {bubbleText && !hideBubble && (
        <div className='bubble'>
          <div className='bubble-text'>{bubbleText}</div>
        </div>
      )}
      <div style={{ width: '100%' }}>
        <div style={{ padding: 16, borderBottom: hasWarning ? `2px solid ${colorWarning}` : 'inherit' }}>
          <Stack direction='column' justifyContent='space-between'>
            <Stack direction='column' justifyContent='space-between'>
              <Stack direction='row' justifyContent='space-between'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='text-xl bold'>{name}</div>
                </div>
                <div style={{ color: colorFontDark, display: 'flex' }}>
                  <span style={{ alignSelf: 'flex-start', fontSize: '12px' }}>$</span>
                  <span className='text-xl'>{price}</span>
                  <span style={{ alignSelf: 'center', fontSize: '12px' }}>/{period === 'monthly' ? 'mo' : 'yr'}</span>
                </div>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <div className='base-label subtitle'>{subtitle}</div>
                {lowerRightText && <div className='base-label'>{lowerRightText}</div>}
              </Stack>
            </Stack>
            <Collapse in={focused} unmountOnExit>
              <Stack direction='column' justifyContent='space-between' spacing={1}>
                {features?.map(({ text, included }, index) => (
                  <SubscriptionFeature key={index} text={text} included={included} />
                ))}
              </Stack>
            </Collapse>
          </Stack>
        </div>
        <div>
          {hasWarning && (
            <div className='warning'>
              <div className='text-base bold mb-8'>{warningTitle}</div>
              <div className='text-base'>{warningDescription}</div>
            </div>
          )}
        </div>
      </div>
    </Button>
  );
};

export default SubscriptionTypeElement;
