import { baseApi, baseQueryWithAuth, TAGS } from '@app/src/api/baseApi';

const baseQueryFn =
  ({ query, transformResponse }) =>
  async (args, api, extraOptions) => {
    const result = await baseQueryWithAuth(query(args), api, extraOptions);

    if (transformResponse && !result.error && result.data) {
      return {
        ...result,
        data: transformResponse(result.data)
      };
    }

    return result;
  };

export const taxProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createTaxProfile: builder.mutation({
      query: (year) => ({
        url: `tax-profile/${year}`,
        method: 'POST'
      }),
      invalidatesTags: [TAGS.TAX_PROFILE, TAGS.TAX_ESTIMATE]
    }),
    getTaxProfile: builder.query({
      queryFn: baseQueryFn({
        query: (year) => ({
          url: `tax-profile/${year}`,
          method: 'GET'
        }),
        transformResponse: (response) => response.data
      }),
      providesTags: [TAGS.TAX_PROFILE]
    }),
    calculateTaxEstimate: builder.query({
      queryFn: baseQueryFn({
        query: ({ year }) => ({
          url: `tax-profile/${year}/estimate`,
          method: 'GET'
        }),
        transformResponse: (response) => response.data
      }),
      providesTags: (_result, _error, { year }) => [{ type: TAGS.TAX_ESTIMATE, id: year }]
    }),
    updateTaxProfile: builder.mutation({
      query: ({ year, profile }) => {
        return {
          url: `tax-profile/${year}`,
          method: 'PATCH',
          body: profile
        };
      },
      invalidatesTags: (_result, _error, { year }) => [
        TAGS.TAX_PROFILE,
        TAGS.WORK_INFO,
        { type: TAGS.TAX_ESTIMATE, id: year }
      ]
    })
  })
});

export const {
  useCalculateTaxEstimateQuery,
  useUpdateTaxProfileMutation,
  useCreateTaxProfileMutation,
  useLazyGetTaxProfileQuery
} = taxProfileApi;
