import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from '@app/src/Components/Common/Loader/Loader';
import FreeTrialRatings from '@app/src/Components/Subscription/SubscriptionContent/FreeTrialRatings';
import FreeTrialContent from '@app/src/Components/Subscription/SubscriptionContent/FreeTrialTimeline';
import RefundCopy from '@app/src/Components/Subscription/SubscriptionContent/RefundCopy';
import SubscriptionFeatures from '@app/src/Components/Subscription/SubscriptionContent/SubscriptionFeatures';
import { setPlansShown } from '@app/src/actions/pricingActions';
import { setSubscriptionModalShow } from '@app/src/actions/taxFlowActions';
import DesktopMobileUi from '@app/src/assets/desktop-mobile-ui.svg?react';
import CanceledImage from '@app/src/assets/keeper-subscription.svg?react';
import DelinquentImage from '@app/src/assets/update-payment.svg?react';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { loadingSelector as expenseReviewLoadingSelector } from '@app/src/selectors/expenseReviewSelectors';
import {
  originSelector,
  subscriptionDefaultsSelector,
  subscriptionStatusSelector
} from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { markSubscriptionPromptAsSeen } from '@app/src/taxflow/sections/submit/services/submitService';
import '@app/src/Components/Subscription/SubscriptionContent/SubscriptionContent.scss';

const SubscriptionContent = ({ onNext, className }) => {
  const dispatch = useDispatch();

  const origin = useSelector(originSelector);

  const expenseReviewLoading = useSelector(expenseReviewLoadingSelector);
  const location = useLocation();
  const loading = location.pathname.includes('/tax-filing') ? false : expenseReviewLoading;

  const defaultValues = useSelector((state) => subscriptionDefaultsSelector(state, 'subscriptionBenefits'));
  const subscriptionStatus = useSelector(subscriptionStatusSelector);

  const title = _.get(defaultValues, 'title');
  const subtitle = _.get(defaultValues, 'subtitle');
  const imageType = _.get(defaultValues, ['body', 'image']);
  const cta = _.get(defaultValues, ['CTA', 'primary', 'text']);
  const primaryPlans = _.get(defaultValues, ['CTA', 'primary', 'plans']);
  const nextScreen = _.get(defaultValues, ['CTA', 'primary', 'nextScreen']);
  const tertiaryCta = _.get(defaultValues, ['CTA', 'tertiary', 'text']);
  const tertiaryPlans = _.get(defaultValues, ['CTA', 'tertiary', 'plans']);
  const ratings = _.get(defaultValues, 'ratings');
  const refundCopy = _.get(defaultValues, 'refundCopy');

  const closeSubscriptionModal = () => {
    dispatch(markSubscriptionPromptAsSeen());
    dispatch(setSubscriptionModalShow(false));
  };

  const handleTertiaryAction = () => {
    if (origin === PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL) {
      trackActivity('tax filing: trial paywall skipped');
      closeSubscriptionModal();
    } else {
      handleContinue(tertiaryPlans);
    }
  };

  useEffect(() => {
    trackActivity('expense review: paywall', { subscriptionStatus, origin });
  }, [subscriptionStatus, origin]);

  const handleContinue = (plansShown) => {
    dispatch(setPlansShown({ plansShown }));
    onNext(nextScreen || 'subscriptionType');
  };

  return loading ? (
    <div className='flex center'>
      <Loader />
    </div>
  ) : (
    <div className={classNames('paywall-modal', className)}>
      <DialogTitle variant='paywall'>{title}</DialogTitle>
      {subtitle && <DialogContentText style={{ marginTop: '8px', textAlign: 'left' }}>{subtitle}</DialogContentText>}
      {imageType === 'keeper-ui' && (
        <div className='paywall-modal-item' style={{ margin: '16px 0' }}>
          <DesktopMobileUi />
        </div>
      )}
      {imageType === 'timeline' && subscriptionStatus === 'pre free trial' && <FreeTrialContent />}
      {imageType === 'features' && <SubscriptionFeatures />}
      {['canceled', 'delinquent'].includes(subscriptionStatus) && (
        <div className='paywall-modal-item' style={{ margin: '16px 0' }}>
          {subscriptionStatus === 'canceled' ? <CanceledImage /> : <DelinquentImage />}
        </div>
      )}
      {refundCopy && <RefundCopy />}
      {ratings && <FreeTrialRatings />}
      <Divider style={{ height: '1px', backgroundColor: '#aaaaab' }} />
      <Stack spacing={1}>
        {tertiaryCta && (
          <div className='paywall-modal-item'>
            <Button sx={{ padding: '4px' }} color='secondary' fullWidth onClick={handleTertiaryAction}>
              {tertiaryCta}
            </Button>
          </div>
        )}
        <div className='paywall-modal-item'>
          <Button variant='contained' color='secondary' fullWidth onClick={() => handleContinue(primaryPlans)}>
            {cta}
          </Button>
        </div>
      </Stack>
    </div>
  );
};

export default SubscriptionContent;
